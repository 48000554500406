module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"QuickSense","short_name":"QuickSense","description":"Everything you need to get an A+ in IGCSE Maths. Questions, Explanations & Analytics included. Shown to increase student test scores by over 20%. Created specifically for Cambridge IGCSE Maths (0508).","lang":"en","display":"standalone","icon":"src/images/favicon.png","start_url":"/","background_color":"#142947","theme_color":"#142947","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"804236e121fe560deaf2e3d3b75cad41"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://p.typekit.net","https://use.typekit.net"],"web":[{"name":["azo-sans-web"],"file":"https://use.typekit.net/cjf8tub.css"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"devKey":"J7Fy5BWFqF9KcsXWeCYdUAA8dVTk7m8m","prodKey":"LizZ6lRGFLTui4KRNRwFtQD5UJg1yKRn","trackPage":true,"trackPageDelay":50,"delayLoad":true,"delayLoadTime":1000,"manualLoad":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.quicksense.org"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
